//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from '@/components/Search';
import Product from '@/components/Product';

export default {
    name: 'Home',
    components: { Search, Product },
    data() {
        return {
            html: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
            topmenu: [],
            uniacid: window.localStorage.getItem('uniacid'),
            moduleList: [],
            leimugoodslist: [],
            banner: [],
            webset: [],
            menu: {
                active: '',
                list: []
            },
            leimulist: [],
            size: 10,
            page: 1,
            pageCount: 0,
            goodsList: [],
            leimu: [],
            nowleimu: '',
            filteredGoods: []
        };
    },
    created() {
        console.log('home...');
        this.getuniacid();
        if (this.$route.query.code) {
            console.log('leimu:', this.$route.query.code);
            this.nowleimu = this.$route.query.code;
        }
    },
    methods: {
        async getuniacid() {
            var ss = window.localStorage.getItem('webinfo');
            if (!ss) {
                var url = window.location.href;
                var dz_url = url.split("#")[0];
                var www_dz_url = dz_url.split("/")[2];
                var centerdomain = www_dz_url.split(".");
                console.log('centerdomain:', centerdomain);
                let res;
                if (centerdomain[1] == 'nowaittech') {
                    res = await this.$http.get('app.layout/weblist', { centerdomain: centerdomain[0] });
                    console.log('websiteinfo:', res);
                } else {
                    res = await this.$http.get('app.layout/weblist', { siteroot: www_dz_url });
                    console.log('websiteinfo:', res);
                }
                console.log('websiteinfo:', res);
                ss = JSON.stringify(res[0]);
            }
            var ssarray = JSON.parse(ss);
            console.log('ss', ss);
            console.log('ssarray.ucid', ssarray['ucid']);
            this.webset = ssarray;
            this.uniacid = 20;
            window.localStorage.setItem('uniacid', this.uniacid);
            document.title = ssarray.webtitle;
            this.getleimugoods();
        },
        async getleimugoods() {
            let res = await this.$http.get('/app.layout/getmulugoods', {
                uniacid: this.uniacid
            });
            if (res) {
                console.log('gsl', res);
                this.leimugoodslist = res;
                window.localStorage.setItem('leimugoodslist', JSON.stringify(res));
                this.filterGoodsByBrand();
                this.getHomeLayout();
                this.getleimu1();
            }
        },
        async getHomeLayout() {
            let res = await this.$http.get('/app.layout', {
                url: '/pages/tabbar/index/index', uniacid: this.uniacid
            });
            if (res.code !== 0) return;
            this.moduleList = res.data.list;
            this.banner = res.data.list.reduce((arr, item) => {
                item.type == 'carousel' && (arr = item.data);
                return arr;
            }, []);
            console.log('banner', this.banner);
        },
        async getleimu1() {
            console.log('mulu1');
            let res = await this.$http.get('/app.layout/mulu1', {
                uniacid: this.uniacid
            });
            if (res) {
                console.log(res);
                this.leimulist = res;
                console.log('ok');
                console.log(this.leimulist);
                let locale = window.localStorage.getItem('locale') || this.$i18n.locale;
                if (locale === 'zh-CN') {
                    for (let i = 0; i < res.length; i++) {
                        const str = chineseLanguageLoader(res[i].title, { language: 'zh-CN' });
                        this.leimulist[i].title = str;
                    }
                }
            }
        },
        filterGoodsByBrand() {
            // Retrieve the JSON from localStorage
            let storedGoodsList = JSON.parse(window.localStorage.getItem('leimugoodslist'));
            
            // Iterate through each category and filter the goods based on the brand "Unique fencing"
            for (let category in storedGoodsList) {
                if (storedGoodsList.hasOwnProperty(category)) {
                    const goods = storedGoodsList[category].goods;
                    const filtered = goods.filter(item => item.brand === 'Leon Paul ');
                    this.filteredGoods = this.filteredGoods.concat(filtered);
                }
            }
        }
    }
};
